import React from "react";

import { accolade, quote, source } from "./acolades_styles.css";

type Props = {
  data: Queries.SanityAccolade;
};

export const Accolade = ({ data }: Props) => (
  <blockquote className={accolade}>
    <p className={source}>{data.source}</p>
    <p className={quote}>{data.excerpt}</p>
  </blockquote>
);
