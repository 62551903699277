import React from "react";

type Props = {
  data: Queries.bookFragment | { purchase: string };
};

export const PurchaseLink = ({ data }: Props) => {
  if (!data.purchase) return <></>;

  return (
    <a href={data.purchase} target="_blank" rel="noopener noreferrer">
      Buy online
    </a>
  );
};
