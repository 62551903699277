import React from "react";

import { byline } from "./styles.css";

type Data = Queries.bookFragment | { publisher?: string; year?: number };

type Props = {
  data: Data;
};

export const PublicationDetails = ({ data }: Props) => {
  const { publisher, year } = data;
  if (!publisher && !year) return <></>;
  const separator = publisher && year ? ", " : "";

  return (
    <p className={byline}>
      Published{" "}
      {publisher && (
        <>
          by<em> {publisher}</em>
        </>
      )}
      {separator}
      {year}
    </p>
  );
};
