import classnames from "classnames";
import React from "react";
import PortableText from "react-portable-text";

import { Attributions } from "./attribution";
import {
  bookDetails,
  bookDetailsCredits,
  bookDetailsImage,
  bookDetailsInfo,
  bookDetailsTitle,
} from "./book.css";
import { CoverImage } from "./coverImage";
import { PublicationDetails } from "./publicationDetails";
import { PurchaseLink } from "./purchaseLink";

type Props = {
  className?: string;
  children?: React.ReactNode;
  data: Queries.bookFragment;
};

export const BookDetails: React.FC<Props> = ({ className, data }: Props) => {
  const classNames = classnames(className, bookDetails);
  return (
    <article className={classNames}>
      <CoverImage data={data} className={bookDetailsImage} />
      <section className={bookDetailsTitle}>
        <h1>{data.title}</h1>
        <PublicationDetails data={data} />
      </section>
      <section className={bookDetailsInfo}>
        <PortableText content={data.description as any} />
      </section>
      <section className={bookDetailsCredits}>
        <Attributions data={data} />
        <PurchaseLink data={data} />
      </section>
    </article>
  );
};
