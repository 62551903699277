import { graphql, HeadProps, PageProps } from "gatsby";
import * as React from "react";

import { Accolades } from "../components/accolades";
import { BookDetails } from "../components/book";
import { Hero } from "../components/hero";
import PageLayout from "../components/pageLayout";
import { PageSection } from "../components/pageSection";
import { altDominantColor } from "../css/styles/styles.css";
import { getPaletteFromImageData } from "../util/dynamicThemePalette";
import { HeadTemplate } from "./headTemplate";

type TemplateProps = PageProps<Queries.SanityBookQuery>;
type HProps = HeadProps & Pick<TemplateProps, "data">;

const hasAccolades = (article: Queries.bookFragment) =>
  Boolean(article?.accolades?.length);

const hasImage = (article: Queries.bookFragment) => Boolean(article.image);

const BookTemplate: React.FC<TemplateProps> = ({ data }) => {
  const article = data.article as Queries.bookFragment;

  const dynamicPalette = getPaletteFromImageData(article.image, "lightVibrant");

  const hero = hasImage(article) && (
    <Hero
      bgImage={article.image as Queries.SanityRasterImage}
      title={article.title}
    ></Hero>
  );

  const accolades = hasAccolades(article) && (
    <PageSection
      className={altDominantColor}
      backgroundStyle={{ angled: "leftHigh" }}
    >
      <Accolades headline={`Accolades for ${article.title}`} data={article} />
    </PageSection>
  );

  return (
    <PageLayout themeColor={dynamicPalette}>
      {hero}
      <PageSection>
        <BookDetails data={article} />
      </PageSection>
      {accolades}
    </PageLayout>
  );
};

export const query = graphql`
  query SanityBook($id: String) {
    article: sanityBook(_id: { eq: $id }) {
      ...book
    }
  }
`;

export const Head: React.FC<HProps> = ({ data }) =>
  HeadTemplate({ title: data.article?.title || "Book" });

export default BookTemplate;
