import { ComponentProps } from "react";

import { Accolades } from ".";

export const getAccoladeDataFromQuery = (
  data: ComponentProps<typeof Accolades>["data"]
): Queries.SanityAccolade[] | null => {
  if (!data) return null;
  if ("accolades" in data)
    return (data.accolades as Queries.SanityAccolade[]) || null;
  return data || null;
};
