import React from "react";

import { attribution } from "./attribution_styles.css";

type Data = Queries.bookFragment | Queries.SanityAttribution[];

const extractAttribution = (
  data?: Data
): Queries.SanityAttribution[] | null => {
  if (!data) return null;
  if ("shoutout" in data)
    return (data.shoutout as Queries.SanityAttribution[]) || null;
  return data || null;
};

const Attribution = ({ content }: { content: Queries.SanityAttribution }) => (
  <dl className={attribution}>
    <dt>{content.name}</dt>
    <dd>{content.role.map((r) => r?.label).join(", ")}</dd>
  </dl>
);

export const Attributions = ({ data }: { data?: Data }) => {
  const content = extractAttribution(data);
  if (!content?.length) return <></>;
  return (
    <section>
      <h2>Credits</h2>
      {content.map((item) => (
        <Attribution content={item} key={item.name} />
      ))}
    </section>
  );
};
