import React from "react";

import { RasterImage } from "../../rasterImage";
import { coverImage } from "./styles.css";

type Data = Queries.bookFragment | Queries.SanityRasterImage;

type Props = {
  data: Data;
  className?: string;
};

export const CoverImage = ({ data, className }: Props) => {
  if ("publisher" in data && !data.image) return <></>;

  const image = "image" in data ? data.image : data;
  return (
    <section className={className}>
      <RasterImage {...image} className={coverImage} />
    </section>
  );
};
