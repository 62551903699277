import React from "react";

import { Accolade } from "./accolade";
import { accolades, headlineStyle } from "./acolades_styles.css";
import { getAccoladeDataFromQuery } from "./utils";

type Props = {
  headline?: string;
  className?: string;
  data: Queries.bookFragment | Queries.SanityBook | Queries.SanityAccolade[];
};

export const Accolades = ({
  data,
  headline = "Accolades",
  className,
}: Props) => {
  const content = getAccoladeDataFromQuery(data);
  if (!content?.length) return <></>;
  return (
    <section className={className}>
      <h1 className={headlineStyle}>{headline}</h1>
      <section className={accolades}>
        {content.map((item) => (
          <Accolade data={item} key={item.source} />
        ))}
      </section>
    </section>
  );
};
